.date {
    background-color: #c1111d;
    border-radius: 10px;
    padding: 10px;
    text-align: end;
    color: #fff;
    font-size: bold;
}

.date3 {
    background-color: #B626D2;
    border-radius: 10px;
    padding: 10px;
    text-align: end;
    color: #fff;
    font-size: bold;
}

.date2 {
    background-color: #2685D2;
    border-radius: 10px;
    padding: 10px;
    text-align: end;
    color: #fff;
    font-size: bold;
}

.eventdetails,
.eventdetails2,
.eventdetails3 {
    gap: 10px;
    width: 100%;
}

.check button {
    background-color: transparent;
    border: 2px solid #c1111d;
    border-radius: 8px;
}

.eventdetails {
    border: 1px solid #c1111d;
    border-radius: 50px;
    padding: 5px;
}

.eventdetails2 {
    border: 1px solid #2685D2;
    border-radius: 50px;
    padding: 5px;
}

.eventdetails3 {
    border: 1px solid #B626D2;
    border-radius: 50px;
    padding: 5px;
}

.details {
    width: 50%;
    flex-direction: column;
    flex-wrap: wrap !important;
}

.eventnext {
    background-color: #272525;
    color: #fff;
    border-radius: 54px;
}

.box-event img {
    max-width: 565px;
    object-position: center;
    object-fit: cover;
    transition: 1.0s ease-in-out;
    border-top-right-radius: 54px;
    border-bottom-right-radius: 54px;
}

.manny h1 {
    color: #c1111d !important;
}